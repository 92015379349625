import * as React from "react"
import { graphql, HeadFC, Link, useStaticQuery } from "gatsby"
import Header from "../components/header"
import ButtonLink from "../components/button-link"
import Footer from "../components/footer"
import PageTitle from "../components/page-title"
import ImageSection from "../components/image-section"
import Seo from "../components/seo"

const ServicesPage = () => {
  // Fetch all cases at build time
  const { allStrapiService } = useStaticQuery(graphql`
    query {
      allStrapiService {
        nodes {
          title
          slug
          description
        }
      }
    }
  `)

  const steps = [{
    title: 'Kick-off',
    description: 'Eerst bespreken we wat je wensen zijn. Wie wil je bereiken, wat zijn je doelen en welke functies moet de website hebben? Met deze informatie creëren we een strategisch plan.'
  }, {
    title: 'Design',
    description: 'We maken een planning, werken het strategisch plan uit en maken een volledig ontwerp. We werken aan UI en UX design voor een optimale gebruikerservaring.'
  }, {
    title: 'Develop',
    description: 'Van het ontwerp maken we een volledig werkende en responsive website. We maken de website gebruiksvriendelijk en je kunt de site zelf beheren.'
  }, {
    title: 'Launch',
    description: 'Als de website af is en alle functies getest zijn we klaar voor de lancering. Tijd om de site aan de wereld te laten zien. We blijven beschikbaar voor ondersteuning.'
  }]

  return (
    <>
      {/* SEO */}
      <Seo seo={{ metaTitle: 'Diensten' }}/>

      {/* Navigation */}
      <Header/>

      {/* Page Title */}
      <PageTitle data={{
        title: 'Diensten',
        description: ' Wij versterken de online zichtbaarheid van jouw bedrijf. Wij kunnen alles regelen van ontwerp tot aan realisatie en onderhoud van je website.',
        cta: true
      }}/>

      {/* Services */}
      <section className="container mx-auto grid md:grid-cols-2 lg:grid-cols-3 gap-6 my-16 px-6 md:px-0">
        {
          allStrapiService.nodes.map(({title, slug, description}: any, index: number) => (
            <Link key={index} to={`/diensten/${slug}`}  className="p-12 bg-brand-blue-light cursor-pointer flex flex-col gap-4 transition-all">
              <h3 className="text-2xl font-bold  transition-colors">{title}</h3>
              <p>
                {description}
              </p>
              <ButtonLink className="mt-auto">Lees meer</ButtonLink>
            </Link>
          ))
        }
      </section>

      {/* Steps */}
      <section className="container mx-auto my-16 px-6 md:px-6">
        <h1 className="mb-8">Hoe we te werk gaan</h1>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 steps-line before:hidden lg:before:block">
          {steps.map((step: any, index: number) => (
            <div key={index} className="relative">
              <span className="absolute md:relative top-[-3rem] md:top-0 md:inline-block text-[6rem] text-brand-blue-light font-bold mb-6">{index+1}</span>
              <h3 className="text-2xl font-bold text-brand-blue mb-2">{step.title}</h3>
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* More info */}
      <ImageSection image="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1415&q=80">
        <h1>Meer informatie</h1>
        <p>
          Wil je meer weten over wat wij voor jouw bedrijf kunnen betekenen? Neem dan vrijblijvend contact op of plan een gesprek. Heb je nog een onbeantwoordde vraag? Kijk dan eens bij de veelgestelde vragen.
        </p>
        <div className="flex gap-6">
          <ButtonLink to="/veelgestelde-vragen">FAQ</ButtonLink>
          <ButtonLink to="/contact">Neem contact op</ButtonLink>
        </div>
      </ImageSection>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export default ServicesPage

export const Head: HeadFC = () => <title>Website laten maken | Delta Futurum</title>
